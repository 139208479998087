import React from 'react'
import LocalizedLink from './localized-link'

const isHash = (str: string) => /^#/.test(str)
const isInternal = (to: string) => /^\/(?!\/)/.test(to)

export interface MdxLinkProps {
  href: string
  [key: string]: any
}

const MdxLink: React.FC<MdxLinkProps> = ({ href, ...props }) =>
  isHash(href) || !isInternal(href) ? <a {...props} href={href} /> : <LocalizedLink {...props} to={href} />

export default MdxLink
