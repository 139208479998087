import React from 'react'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import MdxLink from '../components/mdx-link'
import Container from '../components/container'

export interface PageProps {
  data: {
    mdx: any
  }
}

const Page: React.FC<PageProps> = ({ data: { mdx } }) => {
  return (
    <MDXProvider
      components={{
        a: MdxLink
      }}
    >
      <Container>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </Container>
    </MDXProvider>
  )
}

export default Page

export const query = graphql`
  query Post($title: String!) {
    mdx(frontmatter: { title: { eq: $title } }) {
      body
    }
  }
`

// mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
